.date-badge {
	vertical-align: top;
	margin-right: 8px;
}

.event-badge {
	vertical-align: top;
	margin-left: 8px;
}

.event-title {
	font-weight: 800;
	font-size: 18px;
	color: black;
}

.blog-old-post-summary {
	max-height: 200px;
	overflow-y: hidden;
	background: -webkit-linear-gradient(#333, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
