.events {
}

.date-badge-header {
}

.date-badge-header-text {
	font-weight: 700;
	padding: 3px;
	font-family: "SpecialElite";
}

.date-badge-header-image {
}

.even-row {
	background-color: #f1f3f4;
}

.odd-row {
	background-color: #ffffff;
}

.event-image {
	text-align: center;
}
