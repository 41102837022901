@font-face {
	font-family: TimesLTStdRoman;
	src: url('./fonts/Times-LT-Std-Roman_44644.ttf');
}

@font-face {
	font-family: GirlsAreWeird;
	src: url('./fonts/girlsareweird.ttf');
}

@font-face {
	font-family: SpecialElite;
	src: url('./fonts/SpecialElite.ttf');
}

@font-face {
	font-family: Cuprum;
	src: url('./fonts/Cuprum.otf');
}

@font-face {
	font-family: Roboto-Regular;
	src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: Playfair;
	src: url('./fonts/PlayfairDisplay-Regular.otf');
}

@font-face {
	font-family: Leander;
	src: url('./fonts/Leander.ttf');
}

@font-face {
	font-family: Radley;
	src: url('./fonts/Radley.otf');
}

@font-face {
	font-family: Splendid66;
	src: url('./fonts/SplendidN.ttf');
}

.font-fine {font-size: 16px;}
.font-small {font-size: 20px;}
.font-medium {font-size: 24px;}
.font-xlarge {font-size: 48px;}

.font-styled {
	font-family: Roboto-Regular;
	color: #585858;
}

.text-shadowed {
	text-shadow: 1px 1px 10px #2A2A2A;
}

.login-form {
  line-height: initial;
  box-shadow: 0px 0px 10px #000000;
  margin-bottom: 10px;
}

.login-form-row {
	margin-left: 10px;
	margin-bottom: 10px;
}

.login-form-row-extra {
	margin-left: 10px;
	margin-bottom: 30px;
}

.menuButton {
}

.title {
	color: white;
	flex-grow: 1;
}

.title.dark {
	color: #AFAFAF;
	flex-grow: 1;
}

.themed-font-logo {
	font-family: GirlsAreWeird;
	font-weight: bold;
	color: #232323;
}

.themed-font-banner {
	font-family: Roboto-Regular;
}

.themed-fg-banner {
	color: mintcream;
}

.themed-bg-banner {
	/*background-color: #a22f7d;*/
	/*background-color: #F39C12;*/
	/*background-color: #fafafa;*/
}

.logo {
	text-align: left;
	/*padding: 20px 0px 20px 25px;*/
}

.bold {
	font-weight: bold;
}

.undecorated {
  text-decoration: none;
  color: inherit;
}

.text-input.form-control {
	border-radius: 8px;
	margin: 0;
}

.font-medium.text-input.form-control {
  font-size: 24px;
  line-height: 24px;
  height: 42px;
}




.phoneLink {
	color: rgb(255,176,1);
	text-decoration: none;
}

.App {
}

.Colors {
	background-color: #cc99ff; /*#880085;*/
	background-color: rgb(255,176,1);
	background-color: rgb(18,128,2);
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	padding: 15px;
	background-color: #292b2c;
	color: #dddddd;
	font-family: Times;
	/*font-size: calc(13px + (26 - 13) * ((100vw - 300px) / (1600 - 300)));*/
	font-size: 18px;
	font-weight: 500;
	border-bottom: 1px solid #292b2c;
	position: relative;
	text-align: right;

	/*min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);*/
}

.App-body {
	background-color: rgb(250,250,250);
	min-height: 100vh;
}

.imbibe-body {
	background-color: #fafafa;
	min-height: 100vh;
}

.App-link {
	color: #61dafb;
}

.nav-bar {

}

.nav-item {
	font-size: calc(18px + (32 - 18) * ((100vw - 300px) / (1600 - 300)));
	font-family: Roboto-Regular;
	cursor: pointer;
	text-decoration: none;
	color: black;
}

.thumbnail:hover {
	position:relative;
	top:-25px;
	left:-35px;
	width:500px;
	height:auto;
	display:block;
	z-index:999;
}

.effectfront {
  border: none;
  margin: 0 auto;
}
.effectfront:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.site-text {
	font-family: Playfair;
}

/* Store item caption button */
.grid-product__title-inner {
	font-family: Radley;
	font-size: 20px;
	font-variant: small-caps;
}

/* Store "Buy Now" button */
.form-control__button-text {
	font-family: Radley;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 819px) {
	.not-mobile {
		display: none;
	}

	.MuiAppBar-root.not-mobile {
		display: none;
	}
}

@media only screen and (min-width: 820px) {
	.only-mobile {
		display: none;
	}
}
