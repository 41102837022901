.date-badge {
	vertical-align: top;
	margin-right: 8px;
}

.event-badge {
	vertical-align: top;
	margin-left: 8px;
}

.event-title {
	font-weight: 800;
	font-size: 18px;
	color: black;
}
